export interface ProcessEnv {
    [key: string]: string | undefined
}

const NEXT_PUBLIC_ETHEREUM_NETWORK_ID: string | undefined = process.env.NEXT_PUBLIC_ETHEREUM_NETWORK_ID

export const getContractAddress = (artifacts: any, contractName: string, key: string, defaultValue?: string | null) => {
    return contractName in artifacts &&
        artifacts[contractName].networks &&
        artifacts[contractName].networks[NEXT_PUBLIC_ETHEREUM_NETWORK_ID ?? '']
        ? artifacts[contractName].networks[NEXT_PUBLIC_ETHEREUM_NETWORK_ID ?? ''][key]
        : defaultValue
}

export const getContractAbi = (artifacts: any, contractName: string) => {
    return contractName in artifacts ? artifacts[contractName].abi : []
}
