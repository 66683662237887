import { getContractAbi, getContractAddress } from './helpers'
import artifacts_nft from '../config/artifacts_nft.json'

const NEXT_PUBLIC_NFT_TOKEN_ADDRESS: string | undefined = process.env.NEXT_PUBLIC_NFT_TOKEN_ADDRESS

export const nftAuctionPools = [
    {
        address: getContractAddress(artifacts_nft, 'NftAuctionETH', 'address', null),
        abi: getContractAbi(artifacts_nft, 'NftAuctionETH'),
        nftAddress: getContractAddress(artifacts_nft, 'RareNft', 'address', NEXT_PUBLIC_NFT_TOKEN_ADDRESS ?? ''),
        nftAbi: getContractAbi(artifacts_nft, 'RareNft'),
    },
]

export const nftPools = [
    {
        id: 1,
        nftStakeId: 1,
        icon: 'nft-collection-1',
        name: 'rising',
        title: 'A Rising Moon',
        balance: 0,
        stakedBalance: 0,
        points: 0,
        depositsEnabled: true,
        symbol: '$RARE',
    },
]

export const nftStakes = [
    {
        id: 1,
        name: 'rareNftStake',
        title: 'Rare Genesis',
        nftAddress: getContractAddress(artifacts_nft, 'RareNft', 'address', NEXT_PUBLIC_NFT_TOKEN_ADDRESS ?? ''),
        nftAbi: getContractAbi(artifacts_nft, 'RareNft'),
        decimals: 18,
        symbol: '$RARE',
        rewardsSymbol: 'points',
        stakeAddress: getContractAddress(artifacts_nft, 'RareNftStake', 'address', null),
        stakeAbi: getContractAbi(artifacts_nft, 'RareNftStake'),
        tokenAddress: getContractAddress(artifacts_nft, 'Rare', 'address', NEXT_PUBLIC_NFT_TOKEN_ADDRESS ?? ''),
        tokenAbi: getContractAbi(artifacts_nft, 'Rare'),
    },
]
